// import { AuthState } from './auth';

export default {
	getIsLoading(state: any) {
		return state.isLoading;
	},
	getNewProcess(state: any) {
		return state.newProcess;
	},
	getStep(state: any) {
		return state.step;
	},
	getIsIdentify(state: any) {
		return state.isIdentify;
	},
	getHasNavigation(state: any) {
		return state.hasNavigation;
	},
	getType(state: any) {
		return state.type;
	},
	getTypeTitle(state: any) {
		return state.typeTitle;
	}
};