import { ActionContext } from 'vuex'
// import { } from './ouvidoria'
import { AxiosError, AxiosResponse } from 'axios'
import { api, setToken, clearToken } from '@/services/api'
import { ApiErrors } from '@/services/apiErrors'

export default {
  async auth(store: any, user: object) { //: Promise<void>
    store.commit('setIsLoading', true);
    clearToken();
    try {
      const response = await api.post('/myprocess/auth', user);
      setToken(response.data.Authorization)
      return response;
    } 
    catch (error) {
      new ApiErrors(error).showError();
      return error
    }
    finally{
      store.commit('setIsLoading', false);
    }
  },
  async sendFormNewProcess(store: any, form: object) { //: Promise<void>
    store.commit('setIsLoading', true);
    try {
      const response = await api.post('/myprocess', form);
      store.commit('setNewProcessstate', response.data);
      return response;
    } 
    catch (error) {
      new ApiErrors(error).showError();
      return error
    }
    finally{
      store.commit('setIsLoading', false);
    }
  },
  async sendAnexos(store: any, files: Array<File>) { //: Promise<void>
    store.commit('setIsLoading', true);
    const data = new FormData();
    files.map( (file: File, i: number) => {
      data.append('file'+i, file);
    })
    // data.append('title', 'title');
    // data.append('description', 'description');
    // data.append('notify', '0');
    // data.append('public', '0');
    try {
      const idProcess = store.getters.getNewProcess.idProcess
      // const process = store.getters.getNewProcess;
      const response = await api.post(`/myprocess/${idProcess}/comment/files`, data);
      return response;
    } 
    catch (error) {
      new ApiErrors(error).showError();
      return error
    }
    finally{
      store.commit('setIsLoading', false);
    }
  }
  // const success = (response: AxiosResponse) => {
  //   console.log(response);
  // }
  // const error = (error: AxiosError) => {
  //   console.log(error);
  //   new ApiErrors(error).showError()
  // }
  // return api.post('/myprocess/auths', user)
  //   .then(success)
  //   .catch(error)
  //   .finally(()=>{store.commit('setIsLoading', false);})
}