import { NotificationProgrammatic as Notification } from 'buefy'

export class ApiErrors {
	error: any
	response: any

	constructor(error: any){
		this.response = error.response;
		console.log(error);
		try {
			this.error = error.toJSON();
		} catch (error) {
			this.notifiError('Desculpe, ocorreu um erro ao enviar os dados');
		}
	}

	showError(){
		// console.log(this.response.data);
		const errorMsg = this.getErrorMsg();
		this.notifiError(errorMsg);
	}

	getErrorMsg(){
		if( this.error.message == "Network Error" ){
			return 'Error na sua conexão com a rede ou internet';
		}
		if(this.response.status == 403){
			return 'Sem permissão par esta operação';
		}
		const errorList = this.response.data.errors;
		if( errorList == undefined || errorList.length == 0){
			return 'Desculpe. Ocorreu um erro no servidor';
		}
		else if( errorList[0].code == 'FIELD_NOT_ACCEPT' ){
			return 'Erro. Algum campo não aceito para esta operação';
		}
		else if( errorList[0].code == 'ERR_FORMAT_FIELD' ){
			return 'Erro. Verifique se todos os campos estão preenchidos corretamente';
		}
		else if(this.response.status == 500){
			return 'Desculpe. Ocorreu um erro no servidor';
		}
		return 'Desculpe. Ocorreu um erro inesperado.';
	}

	notification(type: string, message: string): void {
		Notification.open({
				duration: 7000,
				message: message,
				position: 'is-top-right',
				type: type,
				// hasIcon: true
		})
	}

	notifiError(msg: string): void {
		Notification.open({
				duration: 7000,
				message: msg,
				position: 'is-top-right',
				type: 'is-danger',
				// hasIcon: true
		})
	}

  defaultSuccess(): void {
		Notification.open({
				duration: 7000,
				message: 'Operação realizada com sucesso',
				position: 'is-top-right',
				type: 'is-success',
				// hasIcon: true
		})
	}

}