import Vue from 'vue'
import VueRouter from 'vue-router'
import { verifyToken } from '@/services/api'
import routes from './routes';

Vue.use(VueRouter)

const app = document.getElementById('app')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
	// scrollBehavior() {
	// 	if(app != null){
	// 		app.scrollIntoView()
	// 	}
		
	// }
})
// router protect
router.beforeEach((to, from, next) => {
	if(to.name == "search"){
		if (verifyToken()) {
			next()
		}
		else{
			next('/')
		}
	}
	else{
		next()
	}
	//Proteção de página	
	// if(verifyToken()){
	// 	if(to.name == "/"){
	// 		next('/esic')
	// 	} 
	// 	next()
	// }else{ //não há token
	// 	//Se não for página "login" proteger
	// 	if(to.name != "login"){
	// 		next('/')
	// 	}else{
	// 		next()
	// 	}
	// }
})

export default router
