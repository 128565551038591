export default {
	type: '',
	typeTitle: '',
	step: 0,
	isIdentify: true,
	hasNavigation: false,
	isLoading: false,
	error: false,
	name: "",
	newProcess: {}
}