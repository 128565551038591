

export default {
	setIsLoading(state: any, isLoading: boolean): void {
		state.isLoading = isLoading
	},
	setNewProcessstate(state: any, newProcess: boolean): void {
		state.newProcess = newProcess
	},
	setStep(state: any, step: any): void {
		if( step == 0 ){
			state.isIdentify = true;
			state.hasNavigation = false;
		}
		if( step == 1 ){
			state.hasNavigation = false;
		}
		else if( step > 1 ){
			state.hasNavigation = false;
		}
		state.step = step
	},
	setIsIdentify(state: any, isIdentify: boolean): void {
		state.isIdentify = isIdentify
	},
	setHasNavigation(state: any, hasNavigation: boolean): void {
		state.hasNavigation = hasNavigation
	},
	setType(state: any, type: string): void {
		state.type = type
	},
	setTypeWithTitle(state: any, title: string): void {
		state.typeTitle = title
		switch (title) {
			case "Denúncia":
				state.type = 'denuncia';
				break;
			case "Reclamação":
				state.type = 'reclamacao';
				break;
			case "Solicitação":
				state.type = 'solicitacao';
				break;
			case "Sugestão":
				state.type = 'sugestao';
				break;
			case "Elogio":
				state.type = 'elogio';
				break;
			default:
				state.type = title
				break;
		}
	}
}