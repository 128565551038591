import { ActionContext } from 'vuex'
import { Process, ProcessState } from './process'
import { api, apiFormData, verifyToken } from '@/services/api'

export default {
	async fetchProcess(store: ActionContext<ProcessState, string>, idProcess: string): Promise<void> {
		store.commit('setLoader', true)	
		store.commit('setError', false)	
		verifyToken();
		await api.get(`/myprocess/${idProcess}`).then(
			(response) => {
				store.commit('setProcess', response.data)
			},
			error => {
				store.commit('setError', error)
			}
			)
		store.commit('setLoader', false)
	},

	async searchProcess(store: ActionContext<ProcessState, string>, query: string): Promise<void> {
		store.commit('setLoader', true)
		store.commit('setError', false)	
		store.commit('setSearchProcess', {list:[]})
		verifyToken();
		console.log(query);
		
		await api.get(`/myprocess?`+query).then(
			(response) => {
				if(response.data.list){
					store.commit('setSearchProcess', response.data)
				}else{
					store.commit('setError', 5000)
				}
			},
			error => {
				store.commit('setError', error)
			}
		)
		store.commit('setLoader', false)
	},

  async groupProcess(store: ActionContext<ProcessState, string>, idGroup: string): Promise<void> {
		store.commit('setLoaderList', true)
		store.commit('setError', false)	
		store.commit('setGroupProces', {list:[]})
		
		await api.get(`/group/${idGroup}/process?_limit=300`).then(
			(response) => {
				if(response.data.list){
					store.commit('setGroupProces', response.data)
				}else{
					store.commit('setError', 5000)
				}
			},
			error => {
				store.commit('setError', error)
			}
		)
		store.commit('setLoaderList', false)
	},

  async receiveProcess(store: ActionContext<ProcessState, string>, params: any): Promise<void> {
		store.commit('setLoader', true)
		store.commit('setError', false)	
		// console.log(operation.idGroup, operation.idProcess, operation.data);
    const url = `/group/${params.idGroup}/process/${params.idProcess}/receive`
		await api.post(url, params.data).then(
			() => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setError', error)
			}
		)
		store.commit('setLoader', false)
	},

  async genericOprationProcess(store: ActionContext<ProcessState, string>, params: any): Promise<void> {
		store.commit('setLoader', true)
		store.commit('setError', false)	
		// console.log(operation.idGroup, operation.idProcess, operation.data);
    const url = `/group/${params.idGroup}/process/${params.idProcess}/${params.urlOperation}`
    
    if( params.urlOperation != 'comment/files'){
      await api.post(url, params.data).then(
        () => {
          store.commit('setLoader', false)
        },
        error => {
          store.commit('setError', error)
        }
      )
    }
    else{
      await apiFormData.post(url, params.data).then(
        () => {
          store.commit('setLoader', false)
        },
        error => {
          store.commit('setError', error)
        }
      )
    }
		
		store.commit('setLoader', false)
	},
	
	async createProcess(store: ActionContext<ProcessState, string>, newProcess: any): Promise<void> {
		store.commit('setLoader', true)
		store.commit('setError', false)	
		
		await api.post('/myprocess', newProcess).then(
			() => {
				store.commit('setLoader', false)
			},
			error => {
				store.commit('setError', error)
			}
		)
		store.commit('setLoader', false)
	},

	async setProcess(store: ActionContext<ProcessState, string>, process: Process): Promise<void> {
		store.commit('setSelectProcess', process)	
	}		

}

	
